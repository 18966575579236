/* Category */



.page-type-category .qty-wrapper {
font-size:1em;
margin:5px 0 10px;
}

.products-line .product-image img {
width:44%;
}

.page-type-category .products-line .addto-links-icons.addto-onimage {
float:left;
margin-top:-32px;
position:relative;
z-index:1;
padding-bottom:4px;
padding-right:4px;
margin-left:65px;
}

.page-type-category .products-line .qty-wrapper {
float:right;
margin-top:-33px;
margin-right:29px;
}

.page-type-category .category-title {
    /*margin-bottom: 1%;*/
}

.page-type-category .std dl,
.page-type-category .std p,
.page-type-category .std address, .std blockquote {
    margin: 0;
}

.page-type-category .horizontal-section .page-content
{
    margin: 0px;
    padding: 0px;
}
/**LM - styling of category pages**/
.pager {
     border:none;
}
.toolbar-section .module-category-misc {
     text-align:center;
}
.pagination-bottom .module-category-misc {
     text-align:right;
}
/*Styling of product name*/
.product-listing h2.product-name a {
  text-transform:uppercase;
}
/*Added some space in between elements of the toolbar section*/
@media only screen and (min-width:1200px) {
     .toolbar-section .module-category-misc > div:nth-child(2){
         margin:0 40px;
    }
}
@media only screen and (min-width:1440px) {
     .toolbar-section .module-category-misc > div:nth-child(2){
         margin:0 100px;
    }
}
/*For tablet/mobile devices, set the width of the container to occupy the full width*/
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
/*For mobile devices, reduce the font size of the elements in the toolbar section*/
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li{
         font-size:12px;
    }
}
/*Align the items to left*/
.category-products-listing .add-cart-wrapper {
     padding-bottom:40px;
     text-align:left !important;
     padding-left:4%;
}
.category-products-listing .price-box {
     margin:0;
}
.products-grid.centered .item {
     text-align:left;
}