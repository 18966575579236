/* User */

/*PRODUCT BANNERS*/
.duoclean-banner-outer {
    width:100%;
    background-size:100%;
    max-height:1049px;
    min-height:500px;
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Main_Banner_2_copy.jpg");
    position:relative;
}
.duoclean-banner-inner {
    position: absolute;
    left: 40%;
    top: 20%;
    width: 30%
}
.duoclean-banner-inner-layer {
    position:absolute;
    left:38%;
    top:40%;
    color:#fff;
}
.duoclean-banner-inner-layer p {
    font-size:1.5em;
    line-height:1.2;
}
.duoclean-banner-inner-layer .shop-now {
    position:relative;
    left:25%;
    margin-top:16px;
    padding:0;
    font-family:montserrat;
    background-color:#49256e;
    color:#fff;
    font-size:16px;
    border:none;
    text-transform:uppercase;
}
.multiflex-banner-outer2, .banner-outer2-versatility, .banner-outer2-poweredlift, .banner-outer2-enhancedswivel, .cyclonictech-banner-outer2, .accessoriesinc-banner-outer2, .advancedswivel3-banner-outer2, .accessories2-banner-outer2, .banner-outer2-advancedswivel4, .kliknflip-banner-outer2,.accessoriesinc3-banner-outer2 {
    width:100%;
    background-size:100%;
    max-height:1049px;
    min-height:620px;
    position:relative;
}
.multiflex-banner-outer2 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Multiflex_Tech_Banner.jpg");
}
.banner-outer2-versatility {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Versatility.jpg");
}
.banner-outer2-poweredlift {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Powered_lift_away.jpg");
}
.banner-outer2-enhancedswivel {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Swivel_top.jpg");
}
.cyclonictech-banner-outer2 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/IF200-Banners/Advanced_Cyclonic_Technology.jpg");
}
.accessoriesinc-banner-outer2 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/HV380-Banners/Accessories_Included.jpg");
}
.advancedswivel3-banner-outer2 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/NV600-Banners/Advanced_Swivel_Steering_4.jpg");
}
.accessories2-banner-outer2 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/NV600-Banners/Accessories_4.jpg");
}
.banner-outer2-advancedswivel4 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/NV800-Banners/Advanced_Swivel_Steering_3.jpg");
}
.kliknflip-banner-outer2 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/S6001-Banners/KickNFlip_Technology.jpg");
}
.accessoriesinc3-banner-outer2 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/S6001-Banners/Accessories_5.jpg");
}
.multiflex-banner-inner2,.banner-inner2-versatility, .cyclonictech-banner-inner2, .accessories2-banner-inner2, .kliknflip-banner-inner2, .accessoriesinc3-banner-inner2 {
    position:absolute;
    left:75%;
    top:35%;
}
.multiflex-banner-inner2 h1, .banner-inner2-versatility h1, .banner-inner2-poweredlift h1, .banner-inner2-enhancedswivel h1, .accessoriesinc-banner-inner2 h1,.advancedswivel3-banner-inner2 h1, .accessories2-banner-inner2, .banner-inner2-advancedswivel4 h1, .kliknflip-banner-inner2 h1, .accessoriesinc3-banner-inner2 h1 {
    font-size:24px;
    font-weight:700;
    letter-spacing:0;
    text-align:right;
}

.banner-inner2-poweredlift {
    position:absolute;
    left:79%;
    top:55%;
}
.banner-inner2-enhancedswivel {
    position:absolute;
    left:72%;
    top:55%;
}
.accessoriesinc-banner-inner2 {
    position:absolute;
    left:72%;
    top:35%;
}
.advancedswivel3-banner-inner2 {
    position:absolute;
    left:77%;
    top:45%;
}
.banner-inner2-advancedswivel4 {
    position:absolute;
    left:79%;
    top:50%;
}
.multiflex-banner-inner-layer2 p, .banner-inner-layer2-versatility p, .banner-inner-layer2-poweredlift p, .banner-inner-layer2-enhancedswivel p, .cyclonictech-banner-inner-layer2 p, .accessoriesinc-banner-inner-layer2 p, .advancedswivel3-banner-inner-layer2 p, .accessories2-banner-inner-layer2 p, .banner-inner-layer2-advancedswivel4 p, .kliknflip-banner-inner-layer2 p, .accessoriesinc3-banner-inner-layer2 p {
    font-size:1em;
    line-height:1.5;
    letter-spacing:1px;
    font-family:montserrat;
    text-align:right;
}
 .kliknflip-banner-inner-layer2 {
    position:absolute;
    left:65%;
    top:54%;
}
.multiflex-banner-inner-layer2 {
    position:absolute;
    left:69%;
    top:52%;
}
.banner-inner-layer2-versatility {
    position:absolute;
    left:67%;
    top:54%;
    text-align:right;
}
.banner-inner-layer2-poweredlift {
    position:absolute;
    left:65%;
    top:74%;
    text-align:right;
}
.banner-inner-layer2-enhancedswivel {
    position:absolute;
    left:72%;
    top:74%;
    text-align:right;
}
.advancedswivel3-banner-inner-layer2 {
    position:absolute;
    left:65%;
    top:70%;
}
.cyclonictech-banner-inner-layer2 {
    left:69% !important;
    top:72% !important;
}
.accessoriesinc-banner-inner-layer2 {
    position:absolute;
    left:75%;
    top:54%;
    text-align:right;
}
.accessories2-banner-inner-layer2 {
    position:absolute;
    left:75%;
    top:55%;
}
.banner-inner-layer2-advancedswivel4 {
    position:absolute;
    left:71%;
    top:74%;
    text-align:right;
}
.accessoriesinc3-banner-inner-layer2 {
    position:absolute;
    left:78%;
    top:54%;
}
.smartresponse-banner-outer3, .banner-outer3-truepet1, .banner-outer3-allergen, .ultralight-banner-outer3, .sanitize-banner-outer3 {
    width:100%;
    background-size:100%;
    max-height:1049px;
    min-height:620px;
    position:relative;
}
.smartresponse-banner-outer3 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Smart_Response_Banner.jpg");
}
.banner-outer3-truepet1 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Truepet_Brush.jpg");
}
.banner-outer3-allergen {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Anti_Allergen.jpg");
}
.ultralight-banner-outer3 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/HV380-Banners/Ultra_Lightweight.jpg");
}
.sanitize-banner-outer3 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/S6001-Banners/Sanitize_Floors.jpg");
}
.smartresponse-banner-inner3 h1, .banner-inner3-truepet1 h1, .banner-inner3-allergen, .ultralight-banner-inner3 h1, .sanitize-banner-inner3 h1 {
    font-size:24px;
    font-weight:700;
    letter-spacing:0;
    color:#fff;
}
.smartresponse-banner-inner3, .banner-inner3-truepet1, .ultralight-banner-inner3, .sanitize-banner-inner3 {
    position:absolute;
    left:5%;
    top:35%;
}
.smartresponse-banner-inner3 h1 a, .smartresponse-banner-inner-layer3 p a, .convertstohand-banner-inner4 h1 a, .convertstohand-banner-inner-layer4 p a {
    color:#fff;
}
.banner-inner3-allergen {
    position:absolute;
    left:65%;
    top:25%;
    width:25%;
}
.smartresponse-banner-inner-layer3 p, .banner-inner-layer3-truepet1 p, .banner-inner-layer3-allergen p, .ultralight-banner-inner-layer3 p, .sanitize-banner-inner-layer3 p {
    font-size:1em;
    line-height:1.5;
    letter-spacing:1px;
    font-family:montserrat;
    color:#fff;
}
.smartresponse-banner-inner-layer3, .banner-inner-layer3-truepet1, .ultralight-banner-inner-layer3, .sanitize-banner-inner-layer3 {
    position:absolute;
    left:5%;
    top:54%;
}
.banner-inner-layer3-allergen {
    position:absolute;
    left:10%;
    top:25%;
}
.banner-inner-layer3-allergen h1 {
    font-size:24px;
    font-weight:700;
    letter-spacing:0;
    color:#fff;
    margin-bottom:10%;
}
.hasslefree-banner-outer4, .removeableion-banner-outer4, .banner-outer4-truepet2, .banner-outer4-poweredlift2, .banner-outer4-hardfloor, .advancedswivel1-banner-outer4, .convertstohand-banner-outer4, .floortoceiling-banner-outer4, .accessories1-banner-outer4, .corded-banner-outer4, .advancedswivel2-banner-outer4, .banner-outer4-accessoriesinc2 {
    width:100%;
    background-size:100%;
    max-height:1049px;
    min-height:620px;
    position:relative;
}
.hasslefree-banner-outer4 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Hassle_Free_Banner.jpg");
}
.removeableion-banner-outer4 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Removable_Ion_flex_1f200.jpg");
}
.banner-outer4-truepet2 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Truepet_Motorised_Brush.jpg");
}
.banner-outer4-poweredlift2 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Powered_lift_away_2.jpg");
}
.banner-outer4-hardfloor {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/NV600-Banners/Hard_Floor_Hero2.jpg");
}
.advancedswivel1-banner-outer4 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/IF200-Banners/Advanced_Swivel_Steering_2.jpg");
}
.convertstohand-banner-outer4 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/IF200-Banners/Converts_to_hand_vacuum.jpg");
}
.floortoceiling-banner-outer4 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/IF200-Banners/Floor_to_Ceiling.jpg");
}
.accessories1-banner-outer4 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/IF200-Banners/Accessories_2.jpg");
}
.corded-banner-outer4 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/HV380-Banners/Corded_Power.jpg");
}
.advancedswivel2-banner-outer4 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/HV380-Banners/Advanced_Swivel_Steering.jpg");
}
.banner-outer4-accessoriesinc2 {
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/NV800-Banners/Accessories_3.jpg");
}
.hasslefree-banner-inner4, .corded-banner-inner4, .banner-inner4-accessoriesinc2 {
    position:absolute;
    left:75%;
    top:35%;
}
 .banner-inner4-truepet2, .banner-inner4-poweredlift2, .advancedswivel2-banner-inner4 {
    position:absolute;
    left:12%;
    top:35%;
}
.removeableion-banner-inner4, .floortoceiling-banner-inner4, .accessories1-banner-inner4 {
    position:absolute;
    left:5%;
    top:35%;
}
.advancedswivel1-banner-inner4 {
    position:absolute;
    left:5.2%;
    top:35%;
}
.banner-inner4-hardfloor {
    position:absolute;
    left:68%;
    top:35%;
}
.convertstohand-banner-inner4 {
    position:absolute;
    left:70%;
    top:35%;
}
.hasslefree-banner-inner4 h1, .banner-inner4-hardfloor h1, .convertstohand-banner-inner4 h1, .corded-banner-inner4 h1, .banner-inner4-accessoriesinc2 h1 {
    font-size:24px;
    font-weight:700;
    letter-spacing:0;
    color:#fff;
    text-align:right;
}
.banner-inner4-truepet2 h1, .advancedswivel1-banner-inner4 h1, .removeableion-banner-inner4 h1, .advancedswivel2-banner-inner4 h1, .accessories1-banner-inner4 h1, .banner-inner4-poweredlift2 h1, .floortoceiling-banner-inner4 h1 {
    font-size:24px;
    font-weight:700;
    letter-spacing:0;
    color:#000;
}
.banner-inner-layer4-truepet2, .banner-inner-layer4-poweredlift2, .advancedswivel1-banner-inner-layer4, .accessories1-banner-inner-layer4, .advancedswivel2-banner-inner-layer4 {
    position:absolute;
    left:12%;
    top:54%;
}
.floortoceiling-banner-inner-layer4 {
    position:absolute;
    left:5%;
    top:52%;
} 
.removeableion-banner-inner-layer4 {
    position:absolute;
    left:5%;
    top:58%;
}
.advancedswivel1-banner-inner-layer4 {
    font-weight: 400;
}
.hasslefree-banner-inner-layer4, .banner-inner-layer4-hardfloor, .corded-banner-inner-layer4 {
    position:absolute;
    left:65%;
    top:54%;
}
.banner-inner-layer4-hardfloor p, .banner-inner-layer4-accessoriesinc2 p {
    text-align:right;
}
.convertstohand-banner-inner-layer4 {
    position:absolute;
    left:71%;
    top:52%;
}
.banner-inner-layer4-accessoriesinc2 {
    position:absolute;
    left:76%;
    top:54%;
}
/*White*/
.hasslefree-banner-inner-layer4 p, .banner-inner-layer4-hardfloor p, .convertstohand-banner-inner-layer4 p, .corded-banner-inner-layer4 p, .banner-inner-layer4-accessoriesinc2 p {
    font-size:1em;
    line-height:1.5;
    letter-spacing:1px;
    font-family:montserrat;
    color:#fff;
    text-align:right;
}
/*Black*/
.removeableion-banner-inner-layer4 p, .banner-inner-layer4-truepet2 p, .banner-inner-layer4-poweredlift2 p, .advancedswivel1-banner-inner-layer4 p, .floortoceiling-banner-inner-layer4 p,.accessories1-banner-inner-layer4 p, .advancedswivel2-banner-inner-layer4 p {
    font-size:1em;
    line-height:1.5;
    letter-spacing:1px;
    font-family:montserrat;
    color:#000;
}
.advancedswivel1-banner-inner-layer4 p {
    font-weight:bold;
}
.retailer-middle {
    margin-top:1.5%;
}
.retailer-links {
    display:inline-block;
    padding:0;
    margin:2%;
    width:100%;
    font-family:montserrat;
    color:#43266d;
    font-weight:bold;
}
.retailer-links .helper {
    display:inline-block;
    height:100%;
    vertical-align:middle;
}
.retailer-links img {
    vertical-align:middle;
    max-height:60px;
    max-width:100%;
    padding-right:4%;
}
/*Mobile Styling*/

/*Header-Sticky*/
#header .nav-container.v-grid-container {
    position:fixed;
    top:0;
    z-index:1000;
    left:0;
    right:0;
    border-bottom:2px solid #ebebeb;
}
/*Header-Sticky END*/
.poweredlift3way-banner-outer {
    width:100%;
    background-size:100%;
    max-height:1049px;
    min-height:620px;
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Main_Banner_4_copy.jpg");
    position:relative;
}
@media screen and (max-width:479px) {
    .poweredlift3way-banner-outer {
        width:100%;
        background-size:100%;
        min-height:200px;
        background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Main_Banner_4_copy.jpg");
        position:relative;
   }
}
.poweredlift3way-banner-inner {
    position:absolute;
    top:10%;
    left:32%
}
.poweredlift3way-banner-inner h1 {
    font-size:24px;
    font-weight:700;
    letter-spacing:0;
    text-align:center;
}
.poweredlift3way-banner-inner p {
    font-size:1em;
    line-height:1.2;
    text-align:center;
}
.carousel-images-wrapper-poweredlift3way {
    width:90%;
    position:absolute;
    left:50%;
    top:40%;
    -webkit-transform:translate(-35%, 10%);
    transform:translate(-35%, 10%);
}
.carousel-images-poweredlift3way {
    display:inline-block;
}
.carousel-images-poweredlift3way img {
    max-height:200px;
}
.carousel-images-poweredlift3way p {
    padding-top:3%;
    padding-bottom:3%;
    font-size:12px;
    text-align:center;
    color:#49256e;
    width:97%;
    font-weight:bold;
    font-family:montserrat;
    background:#fff;
}
.designedsealedfloors-banner-outer {
    width:100%;
    background-size:100%;
    max-height:1049px;
    min-height:500px;
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/S6001-Banners/Designed_Hard_Floors.jpg");
    position:relative;
}
.designedsealedfloors-banner-inner {
    position:absolute;
    top:10%;
    left:32%
}
.designedsealedfloors-banner-inner h1 {
    font-size:24px;
    font-weight:700;
    letter-spacing:0;
    text-align:center;
}
.designedsealedfloors-banner-inner p {
    font-size:1em;
    line-height:1.2;
    text-align:center;
}
.steamblast-banner-outer {
    width:100%;
    background-size:100%;
    max-height:1049px;
    min-height:500px;
    background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/S6001-Banners/Steamblast_background.jpg");
    position:relative;
}
.steamblast-banner-inner {
    position:absolute;
    top:12%;
    left:32%
}
.steamblast-banner-inner h1 {
    font-size:24px;
    font-weight:700;
    letter-spacing:0;
    text-align:center;
}
.steamblast-banner-inner p {
    font-size:1em;
    line-height:1.2;
    text-align:center;
}
.carousel-images-wrapper-steamblast {
    width:90%;
    position:absolute;
    left:50%;
    top:40%;
    -webkit-transform:translate(-35%, 10%);
    transform:translate(-35%, 10%);
}
.carousel-images-steamblast {
    display:inline-block;
    margin-left:2%;
}
.carousel-images-steamblast img {
    max-height:200px;
}
.accessories2-banner-inner2 h1 {
    font-weight:bold;
}
@media screen and (min-width:500px) {
    .cyclonictech-banner-inner2-mobile h1 {
        display: none;
    }   
    .cyclonictech-banner-inner2 h1 {
        display: block;
        font-size:24px;
        font-weight:700;
        letter-spacing:0;
        text-align:right;
    }
}
@media screen and (max-width:479px) {
    .cyclonictech-banner-inner2-mobile {
        position:absolute;
        left: 20%;
        top: 110%;
    }
    .cyclonictech-banner-inner2-mobile h1 {
        display: block;
        font-size:14px;
        font-weight:700;
        letter-spacing:0;
        color:#000;
        text-align:center;
    } 
        .cyclonictech-banner-inner2 h1 {
        display: none;
    }
    cyclonictech-banner-inner-layer2 {
        width: 110%;
        position: absolute;
        left: -4%;
        top: 160%;
   }
}
/*mobile banners*/
@media screen and (max-width:479px) {
    /*.steamblast-banner-outer {
        width:100%;
        background-size:100%;
        min-height:200px;
        background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/S6001-Banners/Steamblast_background.jpg");
        position:relative;
   }*/
    .poweredlift3way-banner-outer {
        width:100%;
        background-size:100%;
        min-height:150px;
        background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/S6001-Banners/Designed_Hard_Floors.jpg");
        position:relative;
   }
    .duoclean-banner-outer {
        width:100%;
        background-size:100%;
        min-height:150px;
        background-image:url("https://cdn-1.staging-mysagestore.com/cb41b2cc48953213e03cfb9f7ed38435/contents/ckfinder/images/Main_Banner_2_copy.jpg");
        position:relative;
   }
    .duoclean-banner-inner, .designedsealedfloors-banner-inner {
        position:absolute;
        left: 0%;
        top: 110%;
   }
    .duoclean-banner-inner img {
        vertical-align:middle;
        max-height:50px;
        max-width:50%;
        padding-right:4%;
   }
    .duoclean-banner-inner-layer {
        position:absolute;
        left:15%;
        top:35%;
        color:#fff;
        text-align:center;
        width:70%;
   }
    .duoclean-banner-inner-layer p {
        font-size:12px;
        line-height:1.2;
        margin:0;
   }
    .duoclean-banner-inner-layer .shop-now {
        position:relative;
        left:1%;
        top:-65%;
        font-family:montserrat;
        background-color:#49256e;
        color:#fff;
        font-size:12px;
        border:none;
        text-transform:uppercase;
   }
    .duoclean-banner-inner-layer button.button span span {
        line-height:2em;
        font-size:12px;
        height:25px;
   }
    .multiflex-banner-outer2, .banner-outer2-versatility, .banner-outer2-poweredlift, .banner-outer2-enhancedswivel, .cyclonictech-banner-outer2, .accessoriesinc-banner-outer2, .advancedswivel3-banner-outer2, .accessories2-banner-outer2, .banner-outer2-advancedswivel4, .kliknflip-banner-outer2, .accessoriesinc3-banner-outer2, .smartresponse-banner-outer3, .banner-outer3-truepet1, .banner-outer3-allergen, .ultralight-banner-outer3, .sanitize-banner-outer3, .hasslefree-banner-outer4, .removeableion-banner-outer4, .banner-outer4-truepet2, .banner-outer4-poweredlift2, .advancedswivel1-banner-outer4, .convertstohand-banner-outer4, .floortoceiling-banner-outer4, .accessories1-banner-outer4, .corded-banner-outer4, .advancedswivel2-banner-outer4, .banner-outer4-accessoriesinc2, .banner-outer4-hardfloor, .designedsealedfloors-banner-outer {
        width:100%;
        background-size:100%;
        min-height:150px;
        position:relative;
        margin-bottom:55%;
   }
    .multiflex-banner-inner2,.banner-inner2-versatility, .accessories2-banner-inner2, .kliknflip-banner-inner2, .accessoriesinc3-banner-inner2, .advancedswivel3-banner-inner2, .banner-inner3-truepet1, .ultralight-banner-inner3, .sanitize-banner-inner3, .hasslefree-banner-inner4, .corded-banner-inner4, .banner-inner4-accessoriesinc2, .removeableion-banner-inner4, .banner-inner4-truepet2, .banner-inner4-poweredlift2, .advancedswivel1-banner-inner4, .floortoceiling-banner-inner4, .accessories1-banner-inner4,.advancedswivel2-banner-inner4, .banner-inner4-hardfloor, .convertstohand-banner-inner4 {
        position:absolute;
        left:32%;
        top:110%;
   }
    .cyclonictech-banner-inner2 {
        position:absolute;
        left:74%;
        top:130%;
   }
    .multiflex-banner-inner2 h1, .banner-inner2-versatility h1, .banner-inner2-poweredlift h1, .banner-inner2-enhancedswivel h1, .accessoriesinc-banner-inner2 h1, .advancedswivel3-banner-inner2 h1, .accessories2-banner-inner2 h1, .banner-inner2-advancedswivel4 h1, .kliknflip-banner-inner2 h1, .accessoriesinc3-banner-inner2 h1, .smartresponse-banner-inner3 h1, .banner-inner3-truepet1 h1, .banner-inner3-allergen, .ultralight-banner-inner3 h1, .sanitize-banner-inner3 h1, .hasslefree-banner-inner4 h1, .banner-inner4-hardfloor h1, .convertstohand-banner-inner4 h1, .corded-banner-inner4 h1, .banner-inner4-accessoriesinc2 h1, .banner-inner4-truepet2 h1, .advancedswivel1-banner-inner4 h1, .removeableion-banner-inner4 h1, .advancedswivel2-banner-inner4 h1, .accessories1-banner-inner4 h1, .banner-inner4-poweredlift2 h1, .floortoceiling-banner-inner4 h1, .banner-inner-layer3-allergen h1, .designedsealedfloors-banner-inner h1 {
        font-size:14px;
        font-weight:700;
        letter-spacing:0;
        color:#000;
        text-align:center;
   }   
    .accessories2-banner-inner2 h1 {
        font-weight:bold;
   }
    .multiflex-banner-inner-layer2, .kliknflip-banner-inner-layer2, .banner-inner-layer2-versatility, .banner-inner-layer2-poweredlift, .banner-inner-layer2-enhancedswivel, /*.cyclonictech-banner-inner-layer2,*/ .advancedswivel3-banner-inner-layer2, .accessoriesinc-banner-inner-layer2, .accessories2-banner-inner-layer2, .banner-inner-layer2-advancedswivel4, .accessoriesinc3-banner-inner-layer2, .smartresponse-banner-inner-layer3, .banner-inner-layer3-truepet1, .ultralight-banner-inner-layer3, .sanitize-banner-inner-layer3, .banner-inner-layer4-truepet2, .banner-inner-layer4-poweredlift2, .floortoceiling-banner-inner-layer4, .accessories1-banner-inner-layer4, .advancedswivel2-banner-inner-layer4, .hasslefree-banner-inner-layer4, .banner-inner-layer4-hardfloor, .corded-banner-inner-layer4, .convertstohand-banner-inner-layer4 {
        position:absolute;
        left:10%;
        top:140%;
        width:80%;
        text-align:center;
   }
    .multiflex-banner-inner-layer2 p, .banner-inner-layer2-versatility p, .banner-inner-layer2-poweredlift p, .banner-inner-layer2-enhancedswivel p, .cyclonictech-banner-inner-layer2 p, .accessoriesinc-banner-inner-layer2 p, .advancedswivel3-banner-inner-layer2 p, .accessories2-banner-inner-layer2 p, .banner-inner-layer2-advancedswivel4 p, .kliknflip-banner-inner-layer2 p, .accessoriesinc3-banner-inner-layer2 p, .smartresponse-banner-inner-layer3 p, .banner-inner-layer3-truepet1 p, .banner-inner-layer3-allergen p, .ultralight-banner-inner-layer3 p, .sanitize-banner-inner-layer3 p, .hasslefree-banner-inner-layer4 p, .banner-inner-layer4-hardfloor p, .convertstohand-banner-inner-layer4 p, .corded-banner-inner-layer4 p, .banner-inner-layer4-accessoriesinc2 p, .removeableion-banner-inner-layer4 p, .banner-inner-layer4-truepet2 p, .banner-inner-layer4-poweredlift2 p, .advancedswivel1-banner-inner-layer4 p, .floortoceiling-banner-inner-layer4 p,.accessories1-banner-inner-layer4 p, .advancedswivel2-banner-inner-layer4 p {
        font-size:10px;
        line-height:12px;
        letter-spacing:1px;
        font-family:montserrat;
        text-align:unset;
        color:#000;
   }
   .designedsealedfloors-banner-inner p {
        font-size:10px;
        line-height:12px;
        letter-spacing:1px;
        font-family:montserrat;
        text-align:center;
        color:#000;
   }
    .banner-inner-layer4-poweredlift2 p, .advancedswivel3-banner-inner-layer2 p {
        font-weight:bold;
   }
    .smartresponse-banner-inner3 {
        position:absolute;
        left:25%;
        top:110%;
        text-align:center;
   }
    .smartresponse-banner-inner-layer3 {
        position:absolute;
        left:5%;
        top:140%;
        width:90%;
        text-align:center;
   }
   .smartresponse-banner-inner3 h1 a, .smartresponse-banner-inner-layer3 p a, .convertstohand-banner-inner4 h1 a, .convertstohand-banner-inner-layer4 p a {
        color:#000;
    }
    .floortoceiling-banner-inner4 {
        position:absolute;
        left:25%;
        top:110%;
   }
    .removeableion-banner-inner-layer4 {
        position:absolute;
        left:10%;
        top:150%;
        width:80%;
        text-align:center;
   }
    .advancedswivel1-banner-inner-layer4 {
        position:absolute;
        left:5%;
        top:52%;
        text-align:center;
   }
   cyclonictech-banner-inner-layer2 p {
        width: 110%;
        position: absolute;
        left: -4%;
        top: 160%;
   }
   .steamblast-banner-outer {
       display: none
   }
}
.pl-3-way-title-mobile h2, .steam-blast-mobile h2 {
    letter-spacing: 1px;
}
.pl-3-way-subtitle-mobile {
    text-align: center;
}
@media screen and (max-width:479px) {
    .product-videos {
        width: 100%;
        height: 100%;
    }
}
@media screen and (min-width:960px) {
    .product-videos {
        height: 100%;
        text-align: center;
    }
    .product-videos .page-content p iframe {
        width: 70%;
        height: 500px;
    }
}

/*Custom CSS add 18 feb 19*/
.accessories1-banner-inner-layer4{
    left:5%;
    top:52%;
}
.hasslefree-banner-inner-layer4 {
    left:69% !important;
}
.banner-inner4-poweredlift2, .banner-inner-layer3-allergen {
    left:5%;
}
.banner-inner-layer4-poweredlift2{
    left:5%;
    top:48%;
}
.advancedswivel3-banner-inner-layer2 {
    left:69%;
    top:68%;
}
.banner-inner4-hardfloor {
    left:74%;
}
.banner-inner-layer4-hardfloor {
    top:52%;
}
.banner-outer4-hardfloor .banner-inner-layer4-hardfloor {
    left:66%;
}
.accessories2-banner-inner2 {
    left:73.5%;
}
.accessories2-banner-inner-layer2 {
    left:79.5% !important;
    top:52%;
}
.banner-inner2-poweredlift{
    left:77%;
}
.banner-inner-layer2-poweredlift {
    left:67%;
    top:71%;
}
.banner-inner-layer2-advancedswivel4 {
    left:75%;
    top:74%;
}
.banner-inner-layer2-advancedswivel4 {
    top:72%;
}
.banner-inner-layer3-allergen h1 {
    margin-bottom:7%;
}
.banner-inner4-truepet2 {
    left:5% !important;
}
.banner-inner-layer4-truepet2 {
    left:5%;
    top:52%;
}
.poweredlift3way-banner-inner {
    left:28%;
}
.carousel-images-wrapper-poweredlift3way {
    -webkit-transform:translate(-28%,10%);
    transform:translate(-28%,10);
}
.threeway-images-home.carousel-images-wrapper{
    width:64% !important;
}
.banner-inner-layer4-accessoriesinc2 {
    left:81.5%;
    top:52%;
}
.duoclean-banner-inner{
    left:36%;
}
.duoclean-banner-inner-layer{
    left:33%;
    text-align:center;
}
.duoclean-banner-inner-layer{
    text-align:center;
}
.corded-banner-inner4{
    left:68%;
}
a:hover {
    color:#243841;
}
.ultralight-banner-inner-layer3{
    top:52%;
}
.corded-banner-inner-layer4{
    left:66%;
    top:51%;
}
.banner-inner2-versatility{
    left:68%;
}
.banner-inner-layer2-versatility{
    left:70.5%;
    top:52%;
}
.banner-inner-layer3-truepet1 {
    top:52%;
}
.advancedswivel2-banner-inner4 {
    left:5%;
}
.advancedswivel2-banner-inner-layer4 {
    left:5%;
    top:52%;
}
.accessoriesinc-banner-inner-layer2 {
    left:80%;
    top:52%;
}
/*LM:start*/
/*Style the variant section*/
#product-options-wrapper {
     margin:10px 0 -15px;
}
#product-options-wrapper dl dt, #product-options-wrapper dl dd {
     display:inline-block;
}
#product-options-wrapper dl dt {
     width:15%;
}
#product-options-wrapper dl dd {
     width:80%;
}
@media only screen and (max-width:1279px) {
     #product-options-wrapper dl dt {
         width:25%;
    }
     #product-options-wrapper dl dd {
         width:65%;
    }
}
#product-options-wrapper .select2-container--default .select2-selection--single {
     border:1px solid #000;
     height:34px;
}
#product-options-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered {
     line-height:34px;
}
#product-options-wrapper .select2-container--default .select2-selection--single .select2-selection__arrow b {
     border-color:#000 transparent transparent;
     border-width:9px 8px 0;
     margin-left:-10px;
}
/*Hide the additional data on variant selection*/
#product-options-wrapper dl dt .valueLabel {
     display:none;
}
/*Shift the hero image to the left*/
.img-box .product-image {
     margin:0;
}
.short-description {
     margin-top:20px;
}
/*Clear All Text*/
.clear-all-text {
     color:#000;
}
/*UOM*/
#unit-messure-wrapper {
     padding-top:10px;
}
/*Add to Cart*/
.add-cart-wrapper.product-options-bottom {
     margin-top:15px;
}
/*Product tabs*/
#product-tabs li a, #product-tabs .acctab {
     text-transform:uppercase !important;
     border:1px solid #000;
     border-bottom:none;
     font-size:16px;
     font-weight:600;
}
#product-tabs .tabs li a:not(.current) {
     background-color:#9F8543;
     color:#fff;
}
#product-tabs .tabs li {
     margin-right:15px;
}
/*Tablet devices*/
@media only screen and (max-width:959px) {
     #product-options-wrapper .select2.select2-container.select2-container--default {
         width:100% !important;
    }
     .gen-tabs .acctab, .gen-tabs .acctab.current, .gen-tabs .acctab:focus, .gen-tabs .acctab:hover {
         background-color:#9F8543;
         color:#fff;
    }
}
/*LM:end*/