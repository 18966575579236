@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Arial';
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #333;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:30px;
	letter-spacing:0.05em;
	text-transform:uppercase;
	font-family: 'Russo One' !important;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:22px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family: 'Arial';
}
h3 {
	font-size:16px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Arial Narrow' !important;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Arial Narrow' !important;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Arial';
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Arial' !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 14px;
	color: #000;
	font-family: 'Arial';
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #000 !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Arial';
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #000;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 5px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #777;
	font-size: 14px;
	font-family: 'Arial';
}
.links > li > a:hover {
	background-color: #9F8543;;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #243841;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #243841;
}
#nav .nav-panel--dropdown a {
	color: #777;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #243841;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #777;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #000;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #243841;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #243841;
	border-right-color: #243841;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #243841 !important;
	padding: 0;
	border-radius: 10px;
	max-height: 40px;
	font-size: 14px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #E5B54C !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size:30px;
	letter-spacing:0.05em;
	text-transform:uppercase;
	font-family: 'Russo One' !important;
	font-weight:bold;
	color:#000;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #243841;
	border-radius: 10px;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Arial';
	font-size: 16px;
}
button.button span {
	font-size: 16px; border-radius:10px;
}
button.button span span {
	padding: 0 24px; height:40px; line-height: 40px; letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 12px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #E5B54C !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000; font-weight: 700;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #ebebeb;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999;
}

/************************** Header **************************/
/*FA icons*/
.links>li>a {
     text-indent:-9999px;
     position:relative;
     font-size:16px;
     padding:0 20px;
     line-height:2em!important;
     text-align:center;
}
.links>li>a::after {
     font-family:fontAwesome;
     text-indent:0;
     background-position:center;
     background-repeat:no-repeat;
     position:absolute;
     top:0;
     left:0;
     width:100%;
}
.links .link-logout::after {
     content:"\f08b";
}
.links .link-login::after {
     content:"\f090";
}
.links .link-register::after {
     content:"\f007";
}
.links .link-admin::after {
     content:"\f013";
}
.links .link-account::after {
     content:"\f007";
}
.links .link-wishlist::after {
     content:"\f004";
}
/*Set all fa icons to have a normal style instead of italics*/
.fa {
     font-style:normal !important;
}
/*Color of fa icons*/
#header .fa, .links>li>a:after{
     color:#243841;
}
.links>li>a:hover:after {
     background-color:#9F8543;
     color:#fff !important;
}
/*centered logo*/
.header-logo-column {
     position:absolute;
     left:45%;
     z-index:9998;
}
.header-logo-column .module-logo {
     position:relative;
     left:-50%;
}
.user-links-col, .search-column {
     width:17%;
}
/*Hide some categories from either side of the logo*/
#nav .nav-item--home.level0, .first-category #nav > li.level0:nth-child(n+4), .second-category #nav > li.level0:nth-child(-n+3){
     display:none;
}
.first-category #nav > li.level0:nth-child(-n+3) {
     float:left;
}
.second-category #nav > li.level0:nth-child(n+4){
     float:right;
}
.first-category, .second-category {
     border-top:1px solid #243841;
}
/*Styling the display of categories*/
/*Increase padding between the categories*/
@media only screen and (min-width:960px) and (max-width:1199px) {
     .nav-regular li.level0>a {
         padding:0 5px;
    }
}
@media only screen and (min-width:1200px) and (max-width:1299px) {
     .nav-regular li.level0>a {
         padding:0 20px;
    }
}
@media only screen and (min-width:1300px) and (max-width:1439px) {
     .nav-regular li.level0>a {
         padding:0 30px;
    }
}
@media only screen and (min-width:1440px) {
     .nav-regular li.level0>a {
         padding:0 50px;
    }
}
/*Reduce the nav menu width for wider screens*/
@media only screen and (min-width:1800px) {
     .first-category, .second-category {
         width:38%;
    }
}
/*Hide the Welcome text for devices less than 1799px*/
@media only screen and (max-width:1799px) {
     .welcome-msg-txt {
         display:none;
    }
}
/*Hide the language text*/
 .lang-switcher .dropdown-toggle .value {
     display:none;
}
 .lang-switcher .dropdown-toggle {
     margin-top:3px;
}

/*Change the text Switch Customer to icon for devices less than 1200px*/
@media only screen and (max-width:1259px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:18px;
         text-indent:0;
         float:left;
    }
}
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
/*Mobile header*/
@media only screen and (max-width:959px) {
     .module-switch-customer .userCode, .module-switch-customer .userName {
         display:none;
    }
     .mobile-header .item-switch-customer, .mobile-header .item-min-cart, .mobile-header .item-search {
         margin-top:30px;
    }
     .mobile-header .item-switch-language {
         margin-top:28px;
    }
     .mobile-header .item-menu-icon {
         margin-left:60px !important;
         margin-top:25px;
    }
     .mobile-header .item-min-cart .hide-below-960 {
         display:block !important;
    }
     .mobile-header .item-min-cart {
         margin-left:20px !important;
    }
     .mobile-header, .mobile-header .grid-column-wrapper, .mobile-header .module-header-multi {
         overflow:unset;
    }
}
@media only screen and (max-width:767px) {
     .mobile-header .module-header-multi > div:not(.item-min-cart){
         margin-left:3px !important;
         margin-right:3px !important;
         text-align:left;
         float:left;
    }
     .mobile-header .item-logo img {
         max-width:120px;
         margin-left:-30px;
    }
     .mobile-header .item-switch-customer {
         width:40px;
         margin-top:5px !important;
    }
     .mobile-header .item-switch-language {
         margin-top:5px !important;
    }
     .mobile-header .item-menu-icon {
         width:30px;
         margin-top:0;
    }
     .mobile-header .item-logo {
         width:70px;
         margin-top:-10px !important;
    }
     .mobile-header .item-min-cart {
         margin-left:50px !important;
         margin-top:3px;
    }
     .mobile-header .item-search {
         width:90%;
         float:none !important;
         margin-top:0;
    }
     .mobile-header {
         margin-left:-3% !important;
         border-bottom:1px solid #A9A29A;
         padding-bottom:10px;
    }
}
.lang-switcher .dropdown-toggle .value {
     display:none;
}
 .lang-switcher .dropdown-toggle {
     margin-top:3px;
}
/*Language flag*/
.dropdown-toggle .dropdown-icon, .dropdown-menu .dropdown-icon {
    text-indent: 0; background-image: none !important;
  }
/*Account icon for tablet/mobile devices*/
@media only screen and (max-width:959px) {
     .fa-vcard:before {
         content:"\f007" !important;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .user-account .mmenu-account-wrapper {
         margin-left:20px;
    }
     .fa-vcard:before {
         content:"\f007" !important;
    }
}
@media only screen and (max-width:767px) {
     .user-account {
         float:right !important;
    }
}
/*Add padding on the top header and align its various elements*/
.desktop-header {
     padding-top:7px;
}
.desktop-header .search-column {
     margin-bottom:5px;
}
.desktop-header .item-welcome-msg, .desktop-header .item-switch-customer, .desktop-header .item-switch-language {
     padding-top:2px;
}
/*Reduce the size of header for certain widths*/
@media only screen and (min-width:1261px) and (max-width:1535px) {
     .header-logo-column .logo img {
         max-width:220px !important;
    }
}
@media only screen and (max-width:1260px) {
     .header-logo-column .logo img {
         max-width:200px !important;
    }
}
@media only screen and (max-width:1093px) {
     .header-logo-column .logo img {
         margin-left:-20px;
         margin-top:10px;
    }
}
@media only screen and (max-width:1000px) {
     .custom-freeshipping-note a, .links>li>a, .nav-regular li.level0>a>span {
         font-size:12px;
    }
}
/**************************Footer**************************/
#footer {
     border-top:1px solid #243841;
}
.footer-links-column h6.block-title.heading {
     font-size:22px;
     font-weight:700;
     font-family:'Arial';
}
@media only screen and (min-width:768px) {
     .footer-links-column.first h6, .footer-links-column.first .item-default{
         margin-left:30px;
    }
}
.footer-links-column .item-default p {
     line-height:34px;
     width:fit-content;
     border-bottom:2px solid #fff;
     transition:border-bottom 2s;
}
.footer-links-column .item-default p:hover > a{
     color:#243841;
}
.footer-links-column .item-default p:hover{
     border-bottom:2px solid #243841;
}
/*Copyright text*/
.footer-bottom-container {
     background-color:#D9D9D9 !important;
}
.footer-bottom-container .item.item-left {
     float:none;
     text-align:center;
}
.footer-bottom-container .footer-copyright {
     color:#243841;
     font-size:1em;
}
@media only screen and (max-width:767px) {
     .footer-links-column {
         margin-top:20px;
         margin-left:20px;
    }
}
@media only screen and (min-width:768px) and (max-width:1199px){
     .footer-links-column h6.block-title.heading {
         font-size:14px;
    }
     .footer-links-column .item-default p a{
         font-size:12px;
    }
     .footer-links-column .item-default p {
         line-height:26px;
    }
}
@media only screen and (min-width:1200px) and (max-width:1259px){
     .footer-links-column h6.block-title.heading {
         font-size:16px;
    }
}
@media only screen and (min-width:1260px) and (max-width:1439px) {
     .footer-links-column h6.block-title.heading {
         font-size:18px;
    }
     .footer-links-column .item-default p a{
         font-size:15px;
    }
}
/*Remove scrollbars*/
.footer-top .section.clearer {
     overflow:hidden;
}
/************************** MICS **************************/


